<template>
  <section>
    <div class="w-full">
      <Toast />
      <div class="w-full rounded-md shadow p-2">
        <div class="w-full">
          <button @click="show = !show" class="flex gap-2">
            <i v-if="show" class="pi pi-chevron-down text-blue-800 text-xs"></i>
            <i v-else class="pi pi-chevron-right text-blue-800 text-xs"></i>
            <p class="text-blue-800 font-bold">UDF</p>
          </button>
        </div>
        <div v-if="show" class="w-full">
          <div class="lg:flex">
            <div class="w-full lg:w-11/12">
              <div class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 py-2 gap-3">
                <div>
                  <div class="w-full flex">
                    <p class="text-xs text-gray-600">Diag. Principal:</p>
                  </div>
                  <div class="w-full mb-1">
                    <AutoComplete
                      class="text-xs w-full"
                      :class="!ordenesStore._udf.diagPpal && creandoOrden ? 'p-invalid' : ''"
                      panelClass="text-xs w-20"
                      inputClass="text-xs w-full"
                      v-model="diagnosticosStore.diagnostico_ppal"
                      :suggestions="listadoDiagnosticos"
                      @complete="buscarDiagnostico(diagnosticosStore._diagnostico_ppal)"
                      @itemSelect="setDiagnostico('ppal', diagnosticosStore._diagnostico_ppal.CdCie)"
                      :v-tooltip="diagnosticosStore._diagnostico_ppal"
                      placeholder="Minimo 3 caracteres para buscar"
                      field="name"
                      id="diagPpal"
                      modelValue="CdCie"
                      :forceSelection="true"
                    >
                    <template #item="slotProps">
                      <div :style="{'text-wrap': 'wrap'}">
                        <span>{{slotProps.item.name}}</span>
                      </div>
                    </template>
                    </AutoComplete>
                    <span v-if="!ordenesStore._udf.diagPpal && creandoOrden" class="text-red-600 text-xs italic">Este campo es requerido</span>
                  </div>
                </div>
                <div>
                  <div class="w-full flex">
                    <p class="text-xs text-gray-600">Diag. Relacionado:</p>
                  </div>
                  <div class="w-full mb-1">
                    <AutoComplete
                      class="text-xs w-full"
                      :class="!ordenesStore._udf.diagRel && creandoOrden ? 'p-invalid' : ''"
                      panelClass="text-xs w-20"
                      inputClass="text-xs w-full"
                      v-model="diagnosticosStore.diagnostico_relacionado"
                      :suggestions="listadoDiagnosticos"
                      @complete="buscarDiagnostico(diagnosticosStore._diagnostico_relacionado)"
                      @itemSelect="setDiagnostico('relacionado', diagnosticosStore._diagnostico_relacionado.CdCie)"
                      placeholder="Minimo 3 caracteres para buscar"
                      field="name"
                      id="diagRel"
                      :forceSelection="true"
                    >
                      <template #item="slotProps">
                        <div :style="{'text-wrap': 'wrap'}">
                          <span>{{slotProps.item.name}}</span>
                        </div>
                      </template>
                    </AutoComplete>
                    <span v-if="!ordenesStore._udf.diagRel && creandoOrden" class="text-red-600 text-xs italic">Este campo es requerido</span>
                  </div>
                </div>
                <div>
                  <div class="w-full flex">
                    <p class="text-xs text-gray-600">Régimen:</p>
                  </div>
                  <div class="w-full mb-1">
                    <Dropdown
                      panelClass="text-xs"
                      class="border border-gray-300 p-0 rounded-md text-xs w-full"
                      optionValue="id"
                      optionLabel="nombre"
                      v-model="ordenesStore._udf.regimen"
                      :options="listadoRegimen"
                      emptyMessage="Debes seleccionar un paciente primero"
                      id="regimen"
                      :filter="true"
                      @hide="cambiarRegimen"
                      @change="cambioCuota()"
                    />
                    <span v-if="!ordenesStore._udf.regimen && creandoOrden" class="text-red-600 text-xs italic">Este campo es requerido</span>
                  </div>
                </div>
                <div>
                  <div class="w-full flex items-center">
                    <p class="text-xs text-gray-600">Portabilidad:</p>
                  </div>
                  <div class="w-full mb-1">
                    <Dropdown
                      @keyup="localizacionSeleccionada = typeof pacientesStore._paciente_seleccionado === 'object' ? pacientesStore._paciente_seleccionado.U_PHR_CodDane : 0"
                      panelClass="text-xs"
                      class="border border-gray-300 p-0 rounded-md text-xs w-full"
                      optionValue="id"
                      optionLabel="name"
                      v-model="ordenesStore.udf.portabilidad"
                      :options="portabilidadOpcionesArr"
                      @change="ordenesStore._udf.portabilidad === 1 ? ordenesStore.udf.localizacion = '' : ''"
                      id="portabilidad"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="pacientesStore._paciente_seleccionado && typeof pacientesStore._paciente_seleccionado === 'object'" class="w-full grid grid-cols-2 md:grid-cols-4 xl:grid-cols-7 text-center">
            <div>
              <p class="text-xs text-gray-600">Paciente:</p>
              <p class="font-medium text-xs text-blue-800">{{ typeof pacientesStore._paciente_seleccionado === 'object' ? pacientesStore._paciente_seleccionado.U_ACS_NmbPct : '' }}</p>
            </div>
            <div>
              <p class="text-xs text-gray-600">Rango / Nivel:</p>
              <p :class="pacientesStore._paciente_seleccionado.rango_nivel === 'Subsidiado - NIVEL INCORRECTO' || pacientesStore._paciente_seleccionado.rango_nivel === 'Contributivo - RANGO INCORRECTO' ? 'p-1 rounded-md bg-red-300' : ''" class="font-medium text-xs text-blue-800">{{ pacientesStore._paciente_seleccionado.rango_nivel }}</p>
            </div>
            <div>
              <p class="text-xs text-gray-600">EPS del Paciente:</p>
              <p class="font-medium text-xs text-blue-800">{{ typeof pacientesStore._paciente_seleccionado === 'object' ? pacientesStore._paciente_seleccionado.U_ACS_NmbEntidad : '' }}</p>
            </div>
            <div>
              <p class="text-xs text-gray-600">Cuota moderadora</p>
              <p class="font-medium text-xs text-blue-800">{{ ordenesStore._udf.cuota_moderadora}}</p>
            </div>
            <div>
              <p class="text-xs text-gray-600">Porcentaje Cuota:</p>
              <p class="font-medium text-xs text-blue-800">{{ ordenesStore._udf.porcentaje_cuota }}%</p>
            </div>
            <div>
              <p class="text-xs text-gray-600">Zonificación:</p>
              <p class="font-medium text-xs text-blue-800">{{ pacientesStore._paciente_seleccionado.Zonificacion }}</p>
            </div>
            <div v-if="ordenesStore._udf.portabilidad === 1">
              <p class="text-xs text-gray-600">Zonificación portada:</p>
              <div>
                <div class="flex justify-center" v-if="ordenesStore._udf.localizacion.CodSap">
                  <p class="font-medium text-xs text-blue-800">{{ ordenesStore._udf.localizacion.Zonificacion }}</p>
                  <button @click="ordenesStore._udf.localizacion = ''">
                    <i class="pi pi-undo text-xs text-green-600 ml-1"></i>
                  </button>
                </div>
                <AutoComplete
                  v-else
                  v-model="ordenesStore._udf.localizacion"
                  :suggestions="listadoLocalizaciones"
                  @complete="obtenerLocalizaciones($event)"
                  field="Zonificacion"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { useMotivosAutorizacionStore } from '../../../../stores/motivos_autorizacion.store'
  import { usePacientesStore } from '../../../../stores/pacientes.store'
  import { useDiagnosticosStore } from '../../../../stores/diagnosticos.store'
  import { useRegimenStore } from '../../../../stores/regimen.store'
  import { computed, onMounted, ref, watch } from 'vue'
  import { useOrdenesStore } from '../../../../stores/ordenes.store'
  import LocalizacionService from '../../../../services/localizacion.service'
  import { useCalculoCuotaStore } from '../../../../stores/calculo_cuota.store'
  import dayjs from 'dayjs'
  export default {
    name: 'Udf',
    emits: ['pacClear'],
    setup () {
      // Servicios
      const _LocalizacionService = ref(new LocalizacionService())
      // Stores
      const diagnosticosStore = useDiagnosticosStore()
      const ordenesStore = useOrdenesStore()
      const motivosAutorizacionStore = useMotivosAutorizacionStore()
      const pacientesStore = usePacientesStore()
      const calculoCuotaStore = useCalculoCuotaStore()
      const regimenStore = useRegimenStore()
      // Referencias
      const show = ref(true)
      const listadoLocalizaciones = ref([])
      const portabilidadOpcionesArr = ref([
        {
          name: 'No',
          id: 0
        },
        {
          name: 'Si',
          id: 1
        }
      ])
      const exoneraCuotaOpcionesArr = ref([
        {
          name: 'No',
          id: '02'
        },
        {
          name: 'Si',
          id: '01'
        }
      ])
      const showButtonEditPac = ref(false)
      // Computadas
      const listadoDiagnosticos = computed(() => diagnosticosStore._listado_diagnosticos)
      const pacienteSeleccionado = computed(() => pacientesStore._paciente_seleccionado)
      const creandoOrden = computed(() => ordenesStore._creando_orden)
      const header = computed(() => ordenesStore._header)
      const listadoRegimen = computed(() => regimenStore._regimen_por_paciente)
      // Before computed
      const localizacionSeleccionada = ref(pacientesStore._paciente_seleccionado.U_PHR_CodDane ? pacientesStore._paciente_seleccionado.U_PHR_CodDane : 0)
      // Metodos
      const obtenerLocalizaciones = () => {
        return _LocalizacionService.value.listarLocalizaciones(ordenesStore._udf.localizacion).then(({ data }) => {
          listadoLocalizaciones.value = data
        })
      }
      const buscarDiagnostico = (diagnostico) => {
        if (diagnostico.length > 2) { diagnosticosStore.obtenerDiagnosticos(diagnostico) }
      }
      const cambiarRegimen = () => {
        activarSelect('portabilidad', 'select', ordenesStore._udf.regimen !== 0 ? ordenesStore._udf.portabilidad : true)
        setMostrarCalcularCuota()
      }
      const activarSelect = (id, type, value) => {
        if (!value) {
          setTimeout(() => {
            if (type === 'input') {
              document.getElementById(id).focus()
            } else {
              document.getElementById(id).click()
            }
          }, 300)
        }
      }
      const cambioCuota = () => {
        if (ordenesStore._udf.regimen === 1 || ordenesStore._udf.regimen === 2) {
          ordenesStore.validarPorcentajeCuota()
        } else {
          calculoCuotaStore.calcularCuota({ Anio: dayjs(header.value.fechaCont).format('YYYY'), Code: listadoRegimen.value.find(a => a.id === ordenesStore._udf.regimen).Cuota }).then((data) => {
            if (data.U_PHR_Regimen === 1) {
              ordenesStore.udf.cuota_moderadora = 0
              if (ordenesStore._udf.mot_autoriza.Codigo === '06' || ordenesStore._udf.mot_autoriza.Codigo === '05') {
                if (ordenesStore._udf.mot_autoriza.CobroCuotaRecuperacion) {
                  ordenesStore.udf.porcentaje_cuota = parseInt(data.U_PHR_PorCobro)
                }
              } else {
                ordenesStore.udf.porcentaje_cuota = 0
              }
            }
            if (data.U_PHR_Regimen === 2) {
              ordenesStore.udf.porcentaje_cuota = 0
              if (ordenesStore._udf.mot_autoriza.CobroCuotaModeradora) {
                ordenesStore.udf.cuota_moderadora = parseInt(data.U_PHR_ValorCuota)
              }
            }
            setMostrarCalcularCuota()
          })
        }
      }
      const setDiagnostico = (tipo, value) => {
        tipo === 'ppal' ? ordenesStore._udf.diagPpal = value : ordenesStore._udf.diagRel = value
      }
      const setMostrarCalcularCuota = () => {
        if (ordenesStore._udf.cuota_moderadora || ordenesStore._udf.porcentaje_cuota) {
          ordenesStore.mostrar_calcular_cuota = true
        } else ordenesStore.mostrar_calcular_cuota = false
      }
      watch(pacienteSeleccionado, (n, v) => {
        if (typeof n === 'object') {
          if (!ordenesStore._udf.diagPpal) {
            activarSelect('diagPpal', 'input', false)
          }
        }
      })
      onMounted(() => {
        regimenStore.obtenerRegimen()
      })
      return {
        show,
        header,
        portabilidadOpcionesArr,
        localizacionSeleccionada,
        showButtonEditPac,
        buscarDiagnostico,
        listadoDiagnosticos,
        obtenerLocalizaciones,
        listadoLocalizaciones,
        exoneraCuotaOpcionesArr,
        dayjs,
        creandoOrden,
        listadoRegimen,
        activarSelect,
        cambiarRegimen,
        cambioCuota,
        pacientesStore,
        motivosAutorizacionStore,
        ordenesStore,
        setDiagnostico,
        pacienteSeleccionado,
        diagnosticosStore
      }
    }
  }
</script>
<style scoped>
  ::v-deep(.p-dropdown ) {
    height: 1.75rem;
    padding: 0rem;
  }
  ::v-deep(.p-dropdown .p-inputtext) {
    font-size: 0.75rem;
    padding: 0.3rem;
  }
  ::v-deep(.p-inputtext) {
    font-size: 0.75rem;
    padding: 0.25rem;
  }
  ::v-deep(.p-dropdown-filter-icon) {
    display: none !important;
  }
</style>
